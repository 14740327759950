<template>
  <div class="publishTaskDetails">

    <div class="flex-box">
      <div class="left">
        <!-- <el-button @click="addTask" type="primary">新增任务</el-button> -->
        <el-button @click="exportScore(1)" type="info">导出被评分教师评分</el-button>
        <el-button @click="exportScore(2)" type="info">导出评分教师分值</el-button>
        <el-button @click="ranking" type="primary">排名</el-button>
        <el-button type="danger" @click="remind(scope.row)"
          >提醒
        </el-button>
      </div>
      <div class="flex-box">
        <el-select v-model="query.status" clearable placeholder="请选择状态">
            <el-option
            v-for="item in statusList"
            :key="item.value"
            :label="item.label"
            :value="item.value">
            </el-option>
        </el-select>
        <el-input
          placeholder="请输入教师姓名"
          class="picker"
          v-model="query.name"
        ></el-input>
        <el-button type="primary" class="picker"
          >查 询</el-button
        >
      </div>
    </div>

    <el-table :data="teacherDetailList" style="width: 100%">
      <!-- <el-table-column
        label="任务名称"
        prop="name"
        align="center"
      ></el-table-column> -->
      <el-table-column
        label="被评分教师"
        prop="manager_name"
        align="center"
      ></el-table-column>
      <el-table-column
        label="评分教师"
        prop="rater"
        align="center"
      ></el-table-column>
      <el-table-column
        label="得分"
        prop="score"
        align="center"
      ></el-table-column>
      <el-table-column
        label="状态"
        prop="statusStr"
        align="center"
      ></el-table-column>
      <!-- <el-table-column
        label="状态"
        prop="status"
        align="center"
      ></el-table-column> -->

      <el-table-column label="操作" width="300px" prop="course" align="center">
        <template slot-scope="scope">
          <el-button :disabled="scope.row.status === 1" size="mini" type="primary" @click="lookScoreDetail(scope.row)"
            >查看
          </el-button>
          <el-button size="mini" type="success" @click="scoreFinishedCondition(scope.row)"
            >完成情况
          </el-button>
          <!-- <el-button type="danger" @click="remind(scope.row)"
            >提醒
          </el-button> -->
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      layout="prev,pager,next"
      background
      :page-count="page.pageCount"
      :page-size="page.currentPage"
      @current-change="taskDetail"
    ></el-pagination>

    <el-dialog
      :visible.sync="scoreDetailDialogVisible"
      width="600px"
    >
      <div class="center">
        <el-form
          
          ref="scoreDetailFormRef"
          label-width="120px"
          
        >
          <div style="margin-bottom:50px;" v-for="(item,index) in lookScoreDetailList" :key="index">
            <el-form-item label="评分教师：" prop="rater">
              <el-input
                disabled
                type="text"
                v-model="item.rater"
              ></el-input>
            </el-form-item>
            <el-form-item label="评分：" prop="score">
              <el-input
                disabled
                type="text"
                v-model="item.score"
              ></el-input>
            </el-form-item>
            <el-form-item label="评价：" prop="opinion">
              <el-input
                disabled
                type="text"
                v-model="item.opinion"
              ></el-input>
            </el-form-item>
          </div>
        </el-form>
      </div>

    </el-dialog>
    
    
  </div>
</template>

<script>
import classSelector from "@/components/selectors/classSelector";
import termSelector from "@/components/selectors/termSelector";

export default {
  components: { classSelector, termSelector },
  name: "publishTaskDetails",
  data() {
    return {
        page: { pageCount: 1, currentPage: 0 },
        score_id:Number(this.$route.query.score_id),
        // 状态列表
        statusList:[
            {value:0,label:'全部'},
            {value:1,label:'未评分'},
            {value:2,label:'已评分'},
            {value:3,label:'未上传'},
            {value:4,label:'评分中'},
        ],
        teacherDetailList:[],
        // 查询
        query:{
            status:'',
            name:''
        },
        
        // 评分详情表单
        // scoreDetailForm:{
        //   rater:'',
        //   score:'',
        //   opinion:''
        // },
        scoreDetailDialogVisible:false,
        lookScoreDetailList:[],

    };
  },
  created() {
    this.taskDetail(this.score_id)
    
  },
  methods: {
    // 导出被评分教师评分  localhost:82/base/exportScoringSystemTea.do?score_id=31
    exportScore(type){
      const that = this;
      let url
      let name
      if(type == 1){
        url = `/base/exportScoringSystemTea.do`
        name = "被评分教师分数模板"
      }else{
        url = `/base/exportScoringSystemRater.do`
        name = "评分教师分值模板"
      }

      let params = new FormData()
      params.append('score_id',this.score_id)

      this.$postFile(url, params)
        .then((res) => {
          const blob = new Blob([res.data], {
            type: "application/vnd.ms-excel;",
          });
          const a = document.createElement("a");
          // 生成文件路径
          let href = window.URL.createObjectURL(blob);
          a.href = href;
          a.download = name;
          // 利用a标签做下载
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(href);
        })
        .catch((error) => {
          that.$message({
            type: "warning",
            message: error.message,
          });
        });
    },

    // exportScore(){
    //   this.$post(`/base/exportScoringSystemTea.do`,{score_id:this.score_id})
    //   .then(res=>{

    //   })
    // },

    // 详情
    taskDetail(id){
      this.$post(`/base/queryScoringSystemDetail.do`,{score_id:id})
      .then(res=>{
        console.log(res)
        this.teacherDetailList = res.data
        
      })
    },
    // 查看评分详情
    lookScoreDetail(row){
      console.log(row)
      // return
      this.$post(`/base/queryScoringSystemDetailByTea.do`,{
        score_id:row.score_id,
        tea_id:row.id
      }).then(res=>{
        console.log(res)
        this.lookScoreDetailList = res.data
      })
      this.scoreDetailDialogVisible = true

    },
    // 评分完成情况
    scoreFinishedCondition(row){
      this.$router.push({
        name:'finishedCondition',
        query:{
          score_id:row.score_id,
          tea_id:row.id
        }
      })
    },
    // 提醒
    remind(row){
      // console.log(row)
      // return
      this.$post(`/base/noticeScoringSystemTea.do`,{
        score_id:this.score_id,
        tea_id:row.id
      }).then(res=>{
        // console.log(res)
        this.$message({
          type:'success',
          message:'提醒成功！'
        })

      }).catch(err=>{
        this.$message({
          type:'error',
          message:'提醒失败！'
        })
      })
    },
    ranking(){
      this.$router.push({
        name:'ranking',
        query:{
          score_id:this.score_id
        }
      })
    },

    // 时间格式
    timeFormat(time){
      let t = new Date(time)
      let year = t.getFullYear()
      let month = (t.getMonth()+1) < 10 ?  '0' + (t.getMonth()+1) : (t.getMonth()+1)
      let date = t.getDate() < 10 ? '0' + t.getDate() : t.getDate()
      let hour = t.getHours() < 10 ? '0' + t.getHours() : t.getHours()
      let mimute = t.getMinutes() < 10 ? '0' + t.getMinutes() : t.getMinutes()
      return `${year}-${month}-${date} ${hour}:${mimute}`
    },
    
  },
};
</script>

<style scoped>
.input-box {
  width: 180px;
  margin: 0 5px;
}

.flex-box {
  display: flex;
  justify-content: space-between;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.picker {
  margin-left: 10px;
}
</style>
